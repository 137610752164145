import React, { useContext } from 'react';
import styles from '../../PositionGrid/PositionGrid.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnFiltersState, OnChangeFn } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import AppContext from '../../../../../../contexts/AppContext';
import DashboardContext from '../../../../../../contexts/DashboardContext';
import { TicketLayout } from '../../../../../../utils/functions/enums';
import InstrumentContext from '../../../../../../contexts/InstrumentContext';
import useOrderTicketAccess from '../../../../../../utils/hooks/useOrderTicketAccess';
import tradingViewStore from '../../../../../../store/tradingViewStore';
import Button from '../../../../../../shared/Button';
import { TradingPositionState } from '../../../../../../gateways/RfpGateway/rfp.types';

const RenderClearFilters = ({
	columnFilters,
	setColumnFilters,
	type,
}: {
	columnFilters: ColumnFiltersState;
	setColumnFilters: OnChangeFn<ColumnFiltersState>;
	type: TradingPositionState;
}) => {
	const { t } = useTranslation();

	const appContext = useContext(AppContext);
	const dashboardContext = useContext(DashboardContext);
	const instrumentContext = useContext(InstrumentContext);

	const orderTicketAccess = useOrderTicketAccess();
	const setTicketLayout = tradingViewStore.use.setTicketLayout();

	let emptyFilteredStateMessage = t('wtr:NO_OPEN_POSITIONS_FOUND');
	let emptyStateMessage = appContext.isJapanAccount ? t('wtr:JP_NO_OPEN_POSITIONS') : t('en:NO_OPEN_POSITIONS');

	if (type === TradingPositionState.pending) {
		emptyFilteredStateMessage = t('wtr:NO_NAMED_PENDING_ORDERS');
		emptyStateMessage = t('wtr:NO_PENDING_ORDERS');
	} else if (type === TradingPositionState.closed) {
		emptyFilteredStateMessage = t('wtr:NO_POSITIONS_FOUND');
		emptyStateMessage = t('wtr:WTR_NO_CLOSED_POSITIONS');
	}

	const handleStartTrading = (event: any) => {
		event.stopPropagation();
		if (orderTicketAccess()) {
			setTicketLayout(TicketLayout.Dock);
			dashboardContext.closeAllOtherTabs();
			dashboardContext.showOrderTicket = true;
			instrumentContext.focusInstrumentSearchInput();
			dashboardContext.startTradingNow = true;
		}
		return false;
	};

	return columnFilters.length > 0 ? (
		<div className={styles.emptyGridContainer}>
			<FontAwesomeIcon className={styles.emptyIcon} icon={['fas', 'search']} size="5x" />
			<div className={styles.emptyStateMessage}>{t('wtr:NO_OPEN_POSITIONS_FOUND')}</div>

			<Button
				type="submit"
				className={dashboardContext.showOrderTicket ? styles.startTrading_Inactive : styles.startTrading_Active}
				label={t('wtr:CLEAR_FILTERS')}
				size="md"
				variant="secondary"
				onClick={() => setColumnFilters([])}
			/>
		</div>
	) : (
		<div className={styles.emptyGridContainer}>
			<FontAwesomeIcon className={styles.emptyIcon} icon={['fal', 'chart-pie']} size="5x" />
			<div className={styles.emptyStateMessage}>
				{appContext.isJapanAccount ? t('wtr:JP_NO_OPEN_POSITIONS') : t('en:NO_OPEN_POSITIONS')}
			</div>
			<Button
				type="submit"
				icon={['fal', 'circle-plus']}
				iconOnhover={['far', 'circle-plus']}
				className={dashboardContext.showOrderTicket ? styles.startTrading_Inactive : styles.startTrading_Active}
				label={t('en:START_TRADING')}
				size="md"
				variant="secondary"
				onClick={handleStartTrading}
			/>
		</div>
	);
};

export default RenderClearFilters;
