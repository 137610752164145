import React, { useContext, useMemo } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import WindowContext, { WTChildWindowType } from '../../../contexts/WindowContext';
import AppContext from '../../../contexts/AppContext';

import accountStatusStore from '../../../store/accountStatusStore';

import styles from './DetachIcon.module.scss';
import { ApplicationStatus } from '../../../utils/functions/enums';
import authStore, { AuthStore } from '../../../store/authStore';

const DetachIcon = ({ url, windowType }: { url: string; windowType: WTChildWindowType }) => {
	const windowContext = useContext(WindowContext);
	const appContext = useContext(AppContext);

	const isChildWindow = appContext.isChildWindow;
	const isArabic = appContext.isArabic;

	const setShowModal = accountStatusStore.use.setShowModal();
	const status = accountStatusStore.use.status();
	const isDemoMode = authStore((store: AuthStore) => store.isDemoMode);

	const { t } = useTranslation();

	const detachWatchlistHandler = useMemo(() => {
		return () => {
			if (isChildWindow) {
				windowContext.window.close();
			} else if (
				status === ApplicationStatus.APPROVED ||
				status === ApplicationStatus.LEVEL1_APPROVED ||
				status === ApplicationStatus.FUNDED_TRADER ||
				isDemoMode
			) {
				const window = windowContext.window;
				windowContext.openChildWindow(windowType, {
					url,
					windowName: windowType,
					windowFeatures: `height=${window.innerHeight},width=${window.innerWidth}`,
				});
			} else {
				setShowModal(true);
			}
		};
	}, [isDemoMode, status]);

	return (
		<div className={styles.iconContainer}>
			<OverlayTrigger
				delay={{ show: 750, hide: 0 }}
				key={'detachWatchlistIcon'}
				placement={isArabic ? 'right' : 'left'}
				overlay={
					<Tooltip className="my-tooltip" id={'detachWatchlist'}>
						{isChildWindow ? t('en:ATTACH') : t('en:DETACH')}
					</Tooltip>
				}
			>
				<FontAwesomeIcon
					icon={['fal', isChildWindow ? 'square-arrow-down-left' : 'square-arrow-up-right']}
					size="1x"
					onClick={detachWatchlistHandler}
				/>
			</OverlayTrigger>
		</div>
	);
};

export default DetachIcon;
