import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import cn from 'classnames';

import { useTranslation } from 'react-i18next';

import styles from './SignalsEmptyState.module.scss';
import Button from '../../../shared/Button';

interface SignalsEmptyStateProps {
	message: any;
	fullHeight?: boolean;
	clearFilters?: boolean;
	showButton?: boolean;
	handleClearFilters?: () => void;
}

const SignalsEmptyState = ({
	message,
	fullHeight,
	clearFilters,
	handleClearFilters,
	showButton,
}: SignalsEmptyStateProps) => {
	const { t } = useTranslation();
	return (
		// <div className={styles.emptyGridContainer}>
		// 	<FontAwesomeIcon className={styles.emptyIcon} icon={['fal', 'signal-slash']} size="5x" />
		// 	<div className={styles.emptyStateMessage}>{message}</div>
		// 	<div className={styles.emptyButtonContainer}>
		// 		<span className={styles.clearFilterText} onClick={handleClearFilters}>
		// 			<FontAwesomeIcon icon={['fal', 'plus-circle']} size="1x" /> {t('wtr:CLEAR_FILTERS')}
		// 		</span>
		// 	</div>
		// </div>

		<div className={styles.emptyGridContainer}>
			<FontAwesomeIcon className={styles.emptyIcon} icon={['fal', 'signal-slash']} />
			<div className={styles.emptyStateMessage}>{message}</div>
			{showButton === false ? (
				<> </>
			) : (
				<Button
					type="submit"
					icon={['fal', 'circle-plus']}
					iconOnhover={['far', 'circle-plus']}
					className={styles.startTrading_Active}
					label={t('wtr:CLEAR_FILTERS')}
					// size="md"
					variant="secondary"
					onClick={handleClearFilters}
				/>
			)}
		</div>
		// <div className={cn(styles.container, fullHeight && styles.fullHeight)}>
		// 	<FontAwesomeIcon className={styles.noSignalIcon} icon={['fal', 'signal-slash']} />
		// 	<span className={styles.message}>{message}</span>
		// 	{clearFilters && (
		// 		<span className={styles.clearFilterText} onClick={handleClearFilters}>
		// 			{t('wtr:CLEAR_FILTERS')}
		// 		</span>
		// 	)}
		// </div>
	);
};

export default SignalsEmptyState;
