import { immer } from 'zustand/middleware/immer';
import { createJSONStorage, persist } from 'zustand/middleware';

import { Account, TAccountStats } from '../gateways/TfboGateway/TfboGateway.types';
import { useStorageDOMEvents } from '../utils/hooks/useStorageDOMEvents';

import { create } from './create';
import createSelectors from './createSelectors';
import { TAppTheme } from '../contexts/AppContext';

export interface TradingAccountStoreActions {
	setSelected: (selected: number) => void;

	setAccounts: (accounts: any[]) => void;

	setAccountStats: (accountStats: any[]) => void;

	setSelectedAccountStats: (selectedAccountStats: any) => void;
	setIsFundedTrader: (isFundedTrader: boolean) => void;
    setAccountMarketType: ({
		isJapanSpread,
		isJapanSubscription,
		isSpreadBetting,
	}: {
		isJapanSpread: boolean;
		isJapanSubscription: boolean;
		isSpreadBetting: boolean;
	}) => void;
	setTheme: (theme: TAppTheme) => void;
	setCurrentAccountUrl: (url: string) => void;
	setIsOneClickTradingActive: (value: boolean) => void;
	reset: () => void;
}

export interface TradingAccountStoreValues {
	selected: number;
	accounts: Account[];
	theme: TAppTheme;
	accountStats: TAccountStats[];
	selectedAccountStats: any;

	// TODO: when refactoring and have time, group these status in a clever way, and update all over the application
	isSpreadBetting: boolean;
	isJapanSpread: boolean;
	isJapanSubscription: boolean;
	isFundedTrader: boolean;
	currentAccountUrl: string;
	isOneClickTradingActive: boolean;
}

export const initialStateTradingAccountStore: TradingAccountStoreValues = {
	selected: -1,
	selectedAccountStats: {},
	accounts: [],
	theme: 'light',
	accountStats: [],
	currentAccountUrl: '',
	isSpreadBetting: false,
	isJapanSpread: false,
	isJapanSubscription: false,
	isFundedTrader: false,
	isOneClickTradingActive: false,
};
export type TradingAccountStore = TradingAccountStoreValues & TradingAccountStoreActions;

const tradingAccountStore = create<TradingAccountStore>()(
	persist(
		immer((set: any) => ({
			...initialStateTradingAccountStore,

			setSelected: (selected: number) => set({ selected }),

			setAccountMarketType: ({
				isJapanSpread,
				isJapanSubscription,
				isSpreadBetting,
			}: {
				isJapanSpread: boolean;
				isJapanSubscription: boolean;
				isSpreadBetting: boolean;
			}) => set({ isJapanSpread, isJapanSubscription, isSpreadBetting }),

			setSelectedAccountStats: (selectedAccountStats: {}) =>
				set((state: TradingAccountStore) => {
					state.selectedAccountStats = { ...selectedAccountStats };
				}),

			setAccounts: (accounts: any) => set({ accounts }),
			setCurrentAccountUrl: (currentAccountUrl: string) => set({ currentAccountUrl }),
			setAccountStats: (accountStats: any[]) => set({ accountStats }),
			setIsFundedTrader: (isFundedTrader: boolean) => set({ isFundedTrader }),
			setIsOneClickTradingActive: (isOneClickTradingActive: boolean) => set({ isOneClickTradingActive }),
			setTheme: (theme) => {
				set(() => ({
					theme,
				}));
			},
			reset: () => set({ ...initialStateTradingAccountStore }),
		})),
		{
			name: 'trading-account-store', // unique name
			storage: createJSONStorage(() => localStorage),
		}
	)
);

useStorageDOMEvents(tradingAccountStore);

export default createSelectors(tradingAccountStore);
