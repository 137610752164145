import React, { useEffect } from 'react';

const useColumnVisibility = (
	table: any,
	tableObj: Record<string, boolean>,
	setColumnVisibility: React.Dispatch<React.SetStateAction<Record<string, boolean>>>,
	name: string
) => {
	useEffect(() => {
		if (table) {
			const localData = localStorage.getItem(name);

			if (!localData) {
				setColumnVisibility((state) => ({
					...state,
					...tableObj,
				}));
				localStorage.setItem(name, JSON.stringify(tableObj));
			} else {
				const data = JSON.parse(localData) as object;
				if (data && typeof data === 'object') {
					setColumnVisibility((state) => ({
						...state,
						...data,
					}));
				}
			}

			const setItemToStorage = () => {
				localStorage.setItem(name, JSON.stringify(table.getState().columnVisibility));
			};

			window.addEventListener('beforeunload', setItemToStorage);

			return () => {
				setItemToStorage();
				window.removeEventListener('beforeunload', setItemToStorage);
			};
		}
	}, [table]);
};

export default useColumnVisibility;
