import React, { useContext, useEffect } from 'react';
import cn from 'classnames';

import useForceRerender from '../../../utils/hooks/useForceRerender';
import useObservable from '../../../utils/hooks/useObservable';
import AppContext from '../../../contexts/AppContext';

import '../../../css/_dark_variables.scss';
import '../../../css/_japan_theming.scss';

import StringUtils from '../../../utils/functions/StringUtils';

import { LanguageCodes } from '../../features/Dashboard/Settings/settingsOptions';
import styles from './ThemeWrapper.module.scss';
import tradingAccountStore from '../../../store/tradingAccountStore';

const ThemeWrapper: React.FC = ({ children }: any) => {
	const forceRerender = useForceRerender();
	const appContext = useContext(AppContext);
	const theme = tradingAccountStore.use.theme();

	// If the appTheme context changes then a force re-render will be executed
	useObservable(appContext.getPropertyChangeStream('appTheme', 'isJapanAccount', 'languageSettings'), () => {
		forceRerender();
	});

	const isJapanese = appContext.isJapanAccount ? 'isJapanese' : '';
	const isThai = appContext.languageSettings === LanguageCodes.THAI ? 'isThai' : '';
	const isVietnamese = appContext.languageSettings === LanguageCodes.VIETNAMESE ? 'isVietnamese' : '';
	const isArab = appContext.languageSettings === LanguageCodes.ARABIC ? 'isArab' : '';
	const isTraditionalChinese =
		appContext.languageSettings === LanguageCodes.CHINESE_TRADITIONAL ? 'isTraditionalChinese' : '';
	const isSimplifiedChinese =
		appContext.languageSettings === LanguageCodes.CHINESE_SIMPLIFIED ? 'isSimplifiedChinese' : '';

	useEffect(() => {
		document.body.classList.remove('light', 'dark');
		document.body.classList.add(theme);

		if (!StringUtils.isNullOrEmpty(isJapanese)) {
			document.body.classList.add(isJapanese);
		} else if (!StringUtils.isNullOrEmpty(isVietnamese)) {
			document.body.classList.add(isVietnamese);
		} else if (!StringUtils.isNullOrEmpty(isThai)) {
			document.body.classList.add(isThai);
		} else if (!StringUtils.isNullOrEmpty(isArab)) {
			document.body.classList.add(isArab);
		} else if (!StringUtils.isNullOrEmpty(isTraditionalChinese)) {
			document.body.classList.add(isTraditionalChinese);
		} else if (!StringUtils.isNullOrEmpty(isSimplifiedChinese)) {
			document.body.classList.add(isSimplifiedChinese);
		} else {
			document.body.classList.remove(
				'isJapanese',
				'isVietnamese',
				'isThai',
				'isArab',
				'isTraditionalChinese',
				'isSimplifiedChinese'
			);
		}
	}, [isJapanese, isVietnamese, isThai, isArab, isTraditionalChinese, isSimplifiedChinese, theme]);

	return <div className={cn(appContext.appTheme || '', styles.fullHeightWidth)}>{children}</div>;
};

export default ThemeWrapper;
