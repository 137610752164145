import React, { useEffect } from 'react';
import { ColumnDef } from '@tanstack/react-table';

const useTableColumnsOrder = (columns: ColumnDef<any, any>[], name: string) => {
	const [columnOrder, setColumnOrder] = React.useState<string[]>([]);

	useEffect(() => {
		const localDataOrder = localStorage.getItem(name);

		if (!columnOrder.length && localDataOrder?.length) {
			const parsedData = JSON.parse(localDataOrder);
			const quantityItem = parsedData.some((item: string) => item === 'Amount' || item === 'Lots');
			if (quantityItem) {
				setColumnOrder(() => columns.map((c) => c.id!));
				localStorage.setItem(name, JSON.stringify(columns.map((c) => c.id!)));
			} else if (parsedData[parsedData.length - 1] === 'close') {
				setColumnOrder(parsedData);
			}
		} else if (!columnOrder.length && !localDataOrder?.length) {
			setColumnOrder(() => columns.map((c) => c.id!));
		} else {
			localStorage.setItem(name, JSON.stringify(columnOrder));
		}
	}, [columnOrder]);

	return { columnOrder, setColumnOrder };
};

export default useTableColumnsOrder;
