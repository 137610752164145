import React, { useState, useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { TThemeSetting } from '../../../../../gateways/UserPreferencesGateway/UserPreferencesGateway.types';

import AppContext from '../../../../../contexts/AppContext';

import useObservable from '../../../../../utils/hooks/useObservable';

import WtrTooltip from '../../../../../shared/WtrTooltip/WtrTooltip';

import WtrToggle from '../../../../../shared/WtrToggle/WtrToggle';
import useSaveUserPreferences from '../../../../../utils/mutations/useSaveUserPreferences';
import tradingAccountStore from '../../../../../store/tradingAccountStore';

const ThemeToggle = () => {
	const appContext = useContext(AppContext);
	const { t } = useTranslation();

	const { mutate: savePreferences } = useSaveUserPreferences();

	const setAppTheme = tradingAccountStore.use.setTheme();

	useObservable(appContext.getPropertyChangeStream('appTheme'), () => {
		setTheme(appContext.appTheme);
	});

	const [theme, setTheme] = useState(appContext.appTheme);
	const isDarkTheme = theme === 'dark';

	const handleClick = async () => {
		let inverted = 'dark';
		let invertedSetting: TThemeSetting = { _type: 'Dark' };

		if (isDarkTheme) {
			inverted = 'light';
			invertedSetting = { _type: 'Light' };
		}
		setTheme(inverted);
		appContext.appTheme = inverted;
		setAppTheme(inverted);
		appContext.userPreferences!.user_prefs.platform.theme = invertedSetting;

		savePreferences();
	};

	return (
		<WtrTooltip
			content={isDarkTheme ? t('wtr:SWITCH_TO_LIGHT_THEME') : t('wtr:SWITCH_TO_DARK_THEME')}
			position="right center"
		>
			<div>
				<WtrToggle
					onChange={handleClick}
					icon={['fas', isDarkTheme ? 'moon' : 'brightness']}
					isActive={isDarkTheme}
					id="themeToggle"
				/>
			</div>
		</WtrTooltip>
	);
};

export default ThemeToggle;
