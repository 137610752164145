import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import DashboardContext from '../../../../../../contexts/DashboardContext';
import { TicketLayout, TradingPositionSide } from '../../../../../../utils/functions/enums';
import useObservable from '../../../../../../utils/hooks/useObservable';
import usePromiseFactory from '../../../../../../utils/hooks/usePromiseFactory';
import useForceRerender from '../../../../../../utils/hooks/useForceRerender';

import useOrderTicketAccess from '../../../../../../utils/hooks/useOrderTicketAccess';

import { Item } from './';
import tradingViewStore from '../../../../../../store/tradingViewStore';
import orderStore from '../../../../../../store/OrderStore/orderStore';

const RightNavTrade = () => {
	const { t } = useTranslation();
	const promiseFactory = usePromiseFactory();
	const forceRerender = useForceRerender();

	const dashboardContext = useContext(DashboardContext);
	const ticketLayout = tradingViewStore.use.ticketLayout();
	const resetOrderStore = orderStore.use.reset();
	const setTradingPosition = orderStore.use.setTradingPosition();
	const setMarketItem = orderStore.use.setMarketItem();

	const { showConfirmTicket, showOrderTicket, showOrderInformation, showNewsFeed } = dashboardContext;

	useObservable(
		dashboardContext.getPropertyChangeStream(
			'showOrderTicket',
			'showOrderInformation',
			'applicationStatus',
			'accountValues',
			'tradingAccount',
			'showNewsFeed',
			'showCloseTicket',
			'showCancelTicket'
		),
		() => {
			promiseFactory.throttle('dashboardContext.propertyChanged', 100).then(() => {
				forceRerender();
			});
		}
	);
	const selected =
		dashboardContext.showOrderTicket ||
		dashboardContext.showConfirmTicket ||
		dashboardContext.showCloseTicket ||
		dashboardContext.showCancelTicket;

	const handleClickTrade = () => {
		dashboardContext.orderModalOpenedFrom = 'watchlist';
		// dashboardContext.orderType = orderType;

		dashboardContext.isEdit = false;

		resetOrderStore();
		setTradingPosition(undefined);
		setMarketItem(undefined);

		if (ticketLayout === TicketLayout.Dock) {
			if (selected) {
				dashboardContext.closeAllOtherTabs();
				return;
			}

			if (showNewsFeed) {
				dashboardContext.showNewsFeed = false;
			}
			if (showOrderInformation) {
				dashboardContext.showOrderTicket = true;
				dashboardContext.showNewsFeed = false;
			} else {
				if (showConfirmTicket) return;
				dashboardContext.showOrderTicket = true;
				dashboardContext.showNewsFeed = false;
				dashboardContext.modifyTicket = false;
			}
			dashboardContext.showOrderInformation = false;
		}
		if (ticketLayout === TicketLayout.Undock) {
			dashboardContext.closeAllOtherTabs();
			dashboardContext.newOrderModalToggle = {
				orderTicket: true,
				confirmOrder: false,
			};
			dashboardContext.showOrderTicket = false;
		}
	};

	return (
		<Item
			onClick={handleClickTrade}
			tooltipText={t('en:Trade')}
			selected={selected}
			icon={[selected ? 'far' : 'fal', 'arrow-right-arrow-left']}
		/>
	);
};

export default RightNavTrade;
