import React, { useContext, useRef, useState, RefObject, createRef } from 'react';

import { useHistory } from 'react-router-dom';

import { useTranslation, Trans } from 'react-i18next';

import Routes from '../../../../../setup/routes';
import { AppComponentType } from '../../../../../utils/functions/enums';
import WtrPopup from '../../../../components/WtrPopup/WtrPopup';
import AppContext from '../../../../../contexts/AppContext';

import useEnableTradersGym from '../../../../../utils/hooks/useEnableTradersGym';

import DashboardContext from '../../../../../contexts/DashboardContext';

import Button from '../../../../../shared/Button';

import InfoModal from '../../../../../components/InfoModal/InfoModal';

import accountStatusStore, { StatusStore } from '../../../../../store/accountStatusStore';

import styles from './Signals.module.scss';

import { Item } from './';

import { IStatusConfigEnum } from '../../../../components/Permissions/config.types';

const TradersGym = () => {
	const appContext = useContext(AppContext);
	const traderGymPopupRef = useRef<HTMLElement>(null);
	const [openTradersGymPopup, setOpenTradersGymPopup] = useState<boolean>(false);
	const enableTradersGym = useEnableTradersGym();
	const setUnlockGym = accountStatusStore.use.setUnlockGym();
	const dashboardContext = useContext(DashboardContext);
	const history = useHistory();
	const [parentRef, setParentRef] = useState<RefObject<HTMLDivElement>>(createRef());
	const { t } = useTranslation();
	const isArabic = appContext.isArabic || localStorage.getItem('language') === 'ar-SA';
	const permissions = accountStatusStore((store: StatusStore) => store.permissions);
	const portalPage = permissions?.portalPage;

	const handlePreClick = (): boolean => {
		// wait until live application status is read:
		// undefined = nodata, false - has incomplete application; true - has live account

		if (enableTradersGym()) {
			return true;
		} else {
			setOpenTradersGymPopup(true);
			document.body.addEventListener('mousedown', (event: MouseEvent): void => {
				if (traderGymPopupRef.current && !traderGymPopupRef.current.contains(event.target as any)) {
					closeTradersGymPopup();
				}
			});
			return false;
		}
	};

	const handleTradersGymPopupCta = () => {
		if (portalPage === IStatusConfigEnum.relevantStep) {
			dashboardContext.presentComponentType = AppComponentType.UploadDocuments;
			history.push(Routes.trader.uploadDocuments, { from: window.location.pathname });
		} else {
			dashboardContext.presentComponentType = AppComponentType.OpenAccount;
			history.push(Routes.trader.openAccount, { from: window.location.pathname });
		}
		setOpenTradersGymPopup(false);
	};

	const closeTradersGymPopup = () => {
		setOpenTradersGymPopup(false);
		setUnlockGym(false);
	};

	return (
		<div ref={parentRef}>
			<WtrPopup
				className=""
				pinned
				on="click"
				position={`${appContext.isArabic ? 'left' : 'right'} center`}
				trigger={
					<Item
						disableTooltip={openTradersGymPopup}
						component={AppComponentType.TradersGym}
						route={Routes.trader.tradersGym}
						icon={'dumbbell'}
						tooltip="en:MORE_SWITCH_GYM"
						preClick={handlePreClick}
					/>
				}
				open={openTradersGymPopup}
				content={<></>}
			/>
			{openTradersGymPopup && (
				<InfoModal
					closeInfoModal={closeTradersGymPopup}
					additionalPositioning={{ x: -12.5, y: -5 }}
					arrow={isArabic ? 'right' : 'left'}
					parentRef={parentRef}
					header={<h1 className={styles.header}>{t('wtr:UNLOCK_TRADERS_GYM')}</h1>}
					content={<Trans i18nKey={'wtr:UNLOCK_TRADERS_GYM_TEXT'} />}
					footer={
						<div className={styles.buttonsContainer}>
							<Button
								className={styles.button}
								label={t('en:CANCEL')}
								variant="secondary"
								size="lg"
								onClick={closeTradersGymPopup}
								isFullWidth={true}
							/>
							<Button
								className={styles.button}
								label={t('en:OPEN_LIVE_ACCOUNT')}
								variant="primary"
								size="fluid"
								onClick={handleTradersGymPopupCta}
								isFullWidth={true}
							/>
						</div>
					}
				/>
			)}
		</div>
	);
};

export default TradersGym;
