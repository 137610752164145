import { ChartTimezone } from '../../utils/functions/enums';

import { TPreferenceResponse } from './UserPreferencesGateway.types';

export const defaultCFDPreference = (tradingMode: 'Live' | 'Demo'): TPreferenceResponse => {
	const userPreferences: TPreferenceResponse = {
		user_prefs: {
			meta: {
				id: 'tt_web',
				app_version: '1.0',
				lastModified: new Date().getMilliseconds().toString(),
			},
			platform: {
				accountType: {
					_name: tradingMode,
				},
				accountTypes: {},
				lang: {
					_code: 'en',
					_name: 'English',
				},
				theme: {
					_type: 'Light',
				},
				timezone: {
					_name: ChartTimezone.Local,
					_gmtOffset: '',
				},
				lastLoggedAct: {
					live: '',
					demo: '',
				},
				inactivity: 21600,
				os: {
					name: 'Web',
					version: '1.0',
				},
			},
			trading: {
				settings: {
					cfd: {
						_mode: 'Regular',
						_quantityType: 'Amount',
						_tradeFromChartEnabled: true,
						_notificationSoundMuted: false,
						_notificationSoundVolume: 50,
					},
					equity: {
						_mode: 'Regular',
						_quantityType: 'Amount',
						_tradeFromChartEnabled: true,
						_notificationSoundMuted: false,
						_notificationSoundVolume: 50,
					},
				},
			},
			calendar: {},
			charts: {
				templates: [],
				studyTemplates: [],
				layout: {},
				childLayout: {},
				drawingTemplates: [],
				settingsTemplate: {},
			},
			search: {},
			watchlists: {
				account: [
					{
						watchlist: [],
						_type: 'Demo',
					},
					{
						watchlist: [],
						_type: 'Live',
					},
					{
						watchlist: [],
						_type: 'Demo',
						accountType: 'SpreadBetting',
					},
					{
						watchlist: [],
						_type: 'Live',
						accountType: 'SpreadBetting',
					},
				],
			},
		},
	};

	return userPreferences;
};
