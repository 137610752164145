import React, { useEffect } from 'react';
import { SortingState } from '@tanstack/react-table';

const useGridSorting = (
	table: any,
	sorting: SortingState,
	setSorting: React.Dispatch<React.SetStateAction<SortingState>>,
	name: string
) => {
	useEffect(() => {
		if (table) {
			const localSortingData = localStorage.getItem(name);

			if (!localSortingData) {
				localStorage.setItem(name, JSON.stringify(sorting));
			} else {
				const data = JSON.parse(localSortingData);
				if (data) {
					setSorting(data);
				}
			}

			const setItemToStorage = () => {
				localStorage.setItem(name, JSON.stringify(table.getState().sorting));
			};

			window.addEventListener('beforeunload', setItemToStorage);

			return () => {
				setItemToStorage();
				window.removeEventListener('beforeunload', setItemToStorage);
			};
		}
	}, [table]);
};

export default useGridSorting;
