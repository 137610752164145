import React, { useMemo, useState, useContext } from 'react';
import cn from 'classnames';
import moment from 'moment/moment';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import { OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltDown, faArrowAltUp } from '@fortawesome/pro-solid-svg-icons';

import AppContext from '../../../../../../../contexts/AppContext';

import tradingAccountStore from '../../../../../../../store/tradingAccountStore';

import useShortTranslation from '../../../../../../../utils/hooks/useShortTranslation';

import Nullable from '../../../../../../../utils/functions/Nullable';
import { TradingPositionSide } from '../../../../../../../utils/functions/enums';
import { getGeneralFormatDate } from '../../../../../../../utils/functions/subscriptionUtils';
import { getFormattedSpreadValue } from '../../../../../../../utils/functions/calculations';

import Tooltip from '../../../../../../components/Tooltip/Tooltip';
import { formattedNumber } from '../../../../Watchlist/Instrument/formattedQuoteNumber';

import { MarketItem, PriceQuote } from '../../../../../../../gateways/RfpGateway/rfp.types';
import authStore from '../../../../../../../store/authStore';
import useTimeSessions from '../../../../../../../utils/hooks/useTimeSessions';

import styles from './OneClickTradeButtons.module.scss';

interface OneClickTradeButtonsProps {
	marketItem: MarketItem;
	instrument?: PriceQuote | null;
	handleBuySellClick: (orderType: 'BUY' | 'SELL') => void;
}

const OneClickTradeButtons = ({ marketItem, instrument, handleBuySellClick }: OneClickTradeButtonsProps) => {
	const appContext = useContext(AppContext);
	const isJapanAccount = authStore.use.isJapanAccount();

	const languageSettings = appContext.languageSettings;

	const { t } = useTranslation();
	const tt = useShortTranslation('en:');

	const [prevBid, setPrevBid] = useState<number>(0);
	const [previousBid, setPreviousBid] = useState(0);
	const [prevAsk, setPrevAsk] = useState<number>(0);
	const [previousAsk, setPreviousAsk] = useState(0);

	const { isWorkingTime } = useTimeSessions();

	const instrumentZero: string = Number(0).toFixed(marketItem?.decPrec);
	const displayPriceA = instrument ? instrument.a : 0;
	const displayPriceB = instrument ? instrument.b : 0;

	const skeletonBaseColor = 'var(--surface-secondary)';
	const skeletonHighlightColor = 'var(--surface-primary)';
	const isSpreadBettingAccount = tradingAccountStore.use.isSpreadBetting();
	const instrumentColor = (left: number, right: number) => {
		if (left === 0 && right === 0) {
			return 'var(-text-secondary)';
		} else if (left <= right) {
			return 'var(--text-negative)';
		} else if (left >= right) {
			return 'var(--text-positive)';
		}
	};

	const instrumentIcon = (left: number, right: number) => {
		if (left === 0 && right === 0) {
			return faArrowAltUp;
		} else if (left <= right) {
			return faArrowAltDown;
		} else if (left >= right) {
			return faArrowAltUp;
		}
		return faArrowAltUp;
	};

	const bidNumber = useMemo(
		() =>
			formattedNumber(
				instrument ? instrument.b : instrumentZero,
				marketItem,
				isSpreadBettingAccount,
				false,
				false,
				languageSettings,
				instrumentColor(displayPriceB, prevBid)
			),
		[instrument, marketItem, instrumentZero, displayPriceB, prevBid, isSpreadBettingAccount]
	);

	const askNumber = useMemo(
		() =>
			formattedNumber(
				instrument ? instrument.a : instrumentZero,
				marketItem,
				isSpreadBettingAccount,
				false,
				false,
				languageSettings,
				instrumentColor(displayPriceA, prevAsk)
			),
		[instrument, marketItem, instrumentZero, displayPriceA, prevAsk, isSpreadBettingAccount]
	);

	Nullable.of(instrument).run((instrument) => {
		if (previousBid !== instrument.b) {
			setPreviousBid(instrument.b);
		}
		if (previousAsk !== instrument.a) {
			setPreviousAsk(instrument.a);
		}
		if (previousBid !== instrument.b) {
			setPrevBid(previousBid);
		}
		if (previousAsk !== instrument.a) {
			setPrevAsk(previousAsk);
		}
	});

	const handleBuySellButtonClick = (orderType: 'BUY' | 'SELL') => {
		if (!instrument) {
			return;
		}
		handleBuySellClick(orderType);
	};

	const timeUpdate = useMemo(
		() => instrument && getGeneralFormatDate(moment(instrument.t).toDate(), true, appContext.isJapanAccount),
		[instrument, marketItem]
	);

	const buttonToolTip = (side: TradingPositionSide) => {
		const isOpenMarket = isJapanAccount ? undefined : isWorkingTime(marketItem, new Date());

		return (
			<div className={styles.tooltipTimestamp}>
				<div className={styles.tooltipTitle}>
					{tt(`${side}_ACTION`)}{' '}
					{marketItem?.exchangeTicker && marketItem?.exchangeTicker !== ''
						? marketItem?.exchangeTicker
						: marketItem?.displayName}
					{isOpenMarket !== undefined && !isOpenMarket && (
						<div className={cn(styles.closedMarketContainer)}>
							&#x2022;
							<div className={cn(styles.closedMarket)}>{t('en:202')}</div>
						</div>
					)}
				</div>

				{isOpenMarket && <div className={styles.timeUpdate}>{timeUpdate}</div>}
			</div>
		);
	};

	return (
		<div className={styles.container}>
			<div
				className={styles.tradeButtonContainer}
				onClick={(e: any) => {
					e.stopPropagation();
					handleBuySellButtonClick('SELL');
				}}
			>
				<OverlayTrigger
					delay={{ show: 300, hide: 0 }}
					key={`sellButton${marketItem?.code}}`}
					placement="bottom"
					overlay={
						<Tooltip className="my-tooltip" id={`sellButton${marketItem?.code}}`}>
							{buttonToolTip(TradingPositionSide.Sell)}
						</Tooltip>
					}
				>
					<div>
						{instrument ? (
							<div className={styles.sellLabelContainer}>
								<FontAwesomeIcon
									icon={instrumentIcon(displayPriceB, prevBid)}
									className={styles.upDownIcon}
									style={{ color: instrumentColor(displayPriceB, prevBid) }}
								/>
								<div>{tt('SELL')}</div>
							</div>
						) : (
							<div className={styles.sellLabelContainer}>
								<Skeleton baseColor={skeletonBaseColor} highlightColor={skeletonHighlightColor} width={50} />
							</div>
						)}

						<div className={styles.priceContainer}>
							{instrument ? (
								bidNumber
							) : (
								<Skeleton baseColor={skeletonBaseColor} highlightColor={skeletonHighlightColor} />
							)}
						</div>
					</div>
				</OverlayTrigger>
			</div>

			<div className={styles.spread}>
				{marketItem && instrument ? (
					getFormattedSpreadValue(marketItem, displayPriceA, displayPriceB, languageSettings)
				) : (
					<Skeleton baseColor={skeletonBaseColor} highlightColor={skeletonHighlightColor} width={35} />
				)}
			</div>

			<div
				className={styles.tradeButtonContainer}
				onClick={(e: any) => {
					e.stopPropagation();
					handleBuySellButtonClick('BUY');
				}}
			>
				<OverlayTrigger
					delay={{ show: 300, hide: 0 }}
					key={`buyButton${marketItem?.code}}`}
					placement="bottom"
					overlay={
						<Tooltip className="my-tooltip" id={`buyButton${marketItem?.code}}`}>
							{buttonToolTip(TradingPositionSide.Buy)}
						</Tooltip>
					}
				>
					<div>
						{instrument ? (
							<div className={styles.buyLabelContainer}>
								<div>{tt('BUY')}</div>
								<FontAwesomeIcon
									icon={instrumentIcon(displayPriceA, prevAsk)}
									className={styles.upDownIcon}
									style={{ color: instrumentColor(displayPriceA, prevAsk) }}
								/>
							</div>
						) : (
							<div className={styles.buyLabelContainer}>
								<Skeleton baseColor={skeletonBaseColor} highlightColor={skeletonHighlightColor} width={50} />
							</div>
						)}
						<div className={styles.priceContainer}>
							{instrument ? (
								askNumber
							) : (
								<Skeleton baseColor={skeletonBaseColor} highlightColor={skeletonHighlightColor} />
							)}
						</div>
					</div>
				</OverlayTrigger>
			</div>
		</div>
	);
};

export default OneClickTradeButtons;
